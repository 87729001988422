





















































































































































































































































































































































































































































































::v-deep .el-table {
    th {
        padding: 0px ;
    }
    thead{
        height: 15px !important;
        color:rgb(71, 70, 70);
        font-size:12px;
    }
    td {
        padding: 3px ;
        font-size:11px;
    }   
}
.el-dropitem {  
    cursor: pointer;
    font-size:15px;
    color:rgb(143, 114, 114) !important;
    height:50px;
    line-height:50px;
    text-align:center;
} 
.el-aside {
    height: calc(100vh - 100px);// vh就是当前屏幕可见高度的1%   只用设置左侧 aside 高度
}
.card-div {
    padding: 14px;
    background:#EBEEF5; 
    text-align:center;
}
.cardhover:hover{ //如有空格表示同级和子级都会受影响
    border :#409EFF 1px solid;
}
.img-div{
    text-overflow:ellipsis; /*对超出容器的部分强制截取，高度不确定则换行*/
    overflow:hidden; /*显示省略符号来代表被修剪的文本。*/
    white-space:nowrap; //禁止换行,文本溢出显示省略号，要显示全内容在DIV上增加title属性
    line-height:20px;
}
.top{
    padding: 10px;
    background: rgba(0, 153, 229);
    color: #fff;
    text-align: center;
    font-size:10px;
    border-radius: 2px;
}  

.group-css{
  display:flex;
  justify-content:space-between;
  flex-wrap:wrap;
  width:1230px;
}
.wraper{
    width:1250px;
    margin:1px auto;
}

